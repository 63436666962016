import React from 'react';

const Footer = () => {
  return (
    <footer className="footer-main-wraper">
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <div className="footer-logo">
              <a href="https://kanoony.com">
                <img src="https://kanoony.com/images/footer-logo.svg" alt="footer logo" />
              </a>
            </div>
          </div>
          <div className="col-md-3">
            <div className="footer-links-wrap">
              <ul>
                <li>
                  <a href="https://kanoony.com/en/contact-us"> Contact Us </a>
                </li>
                <li>
                  <a href="https://kanoony.com/en/privacy-policy"> Privacy Policy </a>
                </li>
                <li>
                  <a href="https://kanoony.com/en/terms-of-use"> Terms of Use </a>
                </li>
                <li>
                  <a href="https://kanoony.com/en/disclaimer"> Disclaimer </a>
                </li>
                <li>
                  <a href="https://kanoony.com/en/faqs"> FAQ </a>
                </li>
                <li>
                  <a href="https://kanoony.com/en/cookies-policy"> Cookies Policy </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-3">
            <a
              href="https://www.dmca.com/Protection/Status.aspx?ID=d25399aa-078f-4d87-965d-86f00a62e95b&amp;refurl=https://kanoony.com/"
              title="DMCA.com Protection Status"
              className="dmca-badge"
            >
              <img
                src="https://images.dmca.com/Badges/_dmca_premi_badge_2.png?ID=d25399aa-078f-4d87-965d-86f00a62e95b"
                alt="DMCA.com Protection Status"
              />
            </a>
            <script src="https://images.dmca.com/Badges/DMCABadgeHelper.min.js"> </script>
          </div>
          <div className="col-md-3">
            <div className="footer-socail-wrap">
              <h4> Follow us on </h4>
              <a href="https://www.facebook.com/kanoonydotcom">
                <img src="https://kanoony.com/images/facebook.webp" alt="facebook" />
              </a>
              <a href="https://www.instagram.com/kanoonydotcom/">
                <img src="https://kanoony.com/images/insta.webp" alt="insta" />
              </a>
              <a href="https://twitter.com/kanoonydotcom">
                <img src="https://kanoony.com/images/twiiter.webp" alt="twitter" />
              </a>
              <a href="https://www.linkedin.com/company/kanoony/">
                <img src="https://kanoony.com/images/linked.webp" alt="linked" />
              </a>
              <a href="https://www.youtube.com/channel/UC7565uq6cicMRdrMOb9gN1Q">
                <img src="https://kanoony.com/images/youtube.png" alt="youtube" />
              </a>
              <div className="footer-card">
                <img src="https://kanoony.com/images/card2-2.png" alt="facebook" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;