import React, { useState, useRef } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import bootstrap from 'bootstrap/dist/js/bootstrap.min.js';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import useSWR from 'swr';
import { fetcher, API_URL } from './helpers/api';

const ListingPage = () => {
  const { category } = useParams();
  const [openIndex, setOpenIndex] = useState(null);

  const handleAccordionClick = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  const [selectedCategories, setSelectedCategories] = useState({});
  const myButtonRef = useRef(null);
  const MODAL_ID = 'exampleModalToggle';
  const STRIPE_MODAL_ID = 'stripeModalToggle';
  const SUCCESS_MODAL_ID = 'successModalToggle';
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const { data: { categories: sub_categories } = {}, subCategoriesError, isValidating: isSubCategoriesLoading } = useSWR(
    API_URL + `/documents-web/${category}`,
    fetcher,
    { revalidateOnFocus: false }
  );


  if (subCategoriesError) {
    console.error('Error fetching Sub categories:', subCategoriesError);
  }

  const toggleModal = (modalId) => {
    const modalToggle = new bootstrap.Modal(document.getElementById(modalId));
    modalToggle.show();
  };

  const closeModal = (modalId) => {
    const modalToggle = new bootstrap.Modal(document.getElementById(modalId));
    modalToggle.hide();
  };

  const triggerClick = () => {
    if (myButtonRef.current) {
      myButtonRef.current.click();
    }
  };

  const getDocumentClasses = (document) => {
    return document.title.length > 60 ? 'truncated' : '';
  };

  const dateFormat = (date) => {
    const originalDateString = date;
    const originalDate = new Date(originalDateString);
    const formattedDate = `${originalDate.getDate().toString().padStart(2, '0')}-${(originalDate.getMonth() + 1).toString().padStart(2, '0')}-${originalDate.getFullYear()}`;
    return formattedDate;
  };

  const getPriceDisplay = (price) => {
    const priceInt = parseInt(price, 10);
    if (priceInt > 0) {
      return `${priceInt} AED`;
    } else {
      return 'Free';
    }
  };

 

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    navigate(`/search?keywords=${encodeURIComponent(searchTerm)}`);
  };

  const handleLinkClick = (slug) => {
    setSelectedCategories('');
    setSelectedCategories((prevSelectedCategories) => ({
      ...prevSelectedCategories,
      [slug]: !prevSelectedCategories[slug],
    }));
  };

  const getCategoryTitle = (category) => {
    let bg = '....';
    if (category === 'employment-hr') {
      bg = 'EMPLOYMENT & HR';
    } else if (category === 'construction-real-estate') {
      bg = 'CONSTRUCTION & REAL ESTATE';
    } else if (category === 'corporate') {
      bg = 'CORPORATE';
    } else if (category === 'finance') {
      bg = 'Finance';
    } else if (category === 'it') {
      bg = 'IT';
    } else if (category === 'commercial') {
      bg = 'Commercial';
    }
    return bg;
  };

  const inputStyle = {
    fontSize: '15px',
    color: 'black',
    width: '90%',
    height: '50px',
    border: '2px solid #dfdfdf',
    borderRadius: '10px',
    padding: '0 10px',
    paddingTop: '0px',
    position: 'initial',
    display: 'block',
    paddingTop: '14px',
    marginLeft: '25px'
  };


  const sectionStyle = {
    backgroundImage: "url(/images/construction-workers.jpg)",
    backgroundPosition: "bottom",
  };

  return (
    <main>
      <div className="inner-aboutslider-wraper">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1> {getCategoryTitle(category)} </h1>
            </div>
            <div className="col-md-12">
              <div className="breadcrumbs-wrap"> <a href="#"> Home </a> <span> </span> <a href="#"> Documents </a> </div>
            </div>
          </div>
        </div>
      </div>
      <section className="content-wraper">
      {/* Document */}
      <div className="inner-document-listing-wraper">
        <div className="container">
          {/* Button, Search */}
          <div className="documen-bt-search">
            <div className="row">
              {/* Button */}
              <div className="col-md-8">
                <div className="button-document">
                <Link to={{pathname: `/free-documents`}}> Free Documents </Link>
                </div>
              </div>
              {/* Button */}

              {/* Search */}
              <div className="col-md-4">
              <form onSubmit={handleSearchSubmit}>
                <div className="search-field-wrap">
                  <input type="text" name="keywords" value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)} className="form-control" placeholder="Search" />
                  <button>
                    <img src="/images/search-news.svg" alt="search" />
                  </button>
                </div>
                </form>
              </div>
              {/* Search */}
            </div>
          </div>
          {/* Button, Search */}
          {isSubCategoriesLoading && (
            <div className="loader-container">
              <div className="loader"></div>
            </div>
          )}
          {/* Listing Accordion #1 */}
          {!isSubCategoriesLoading && sub_categories?.map((sub_category) => (
          <div className="document-listing-accordin-wrap" key={sub_category.title}>
            <h4>{sub_category.title}</h4>
            {sub_category.data?.map((sub, index) => {
              const modifiedString = sub.title.toLowerCase()
              .replace(/[^\w\s]/gi, '')
              .split(' ')
              .join('-');
            return (
            <div id="accordion">
              <div className="card">
                <div className="card-header">
                  <button className="btn" onClick={() => handleAccordionClick(`collapse-${modifiedString}`)}
              aria-expanded={openIndex === `collapse-${modifiedString}`}>
                    <strong style={{padding:'5px'}}>
                      <img src="/images/folder.svg" alt="folder" />
                    </strong>
                    {sub.title}
                    <img src="/images/caret-icon.webp" alt="arrow" />
                  </button>
                </div>
                <div id={`collapse-${modifiedString}`}
            className={`collapse ${openIndex === `collapse-${modifiedString}` ? 'show' : ''}`}
            data-bs-parent="#accordion">
                  <div className="card-body">
                    <div className="document-listing-wraper">
                      <ul className="row">
                      {sub.data?.map((doc, index) => {
                        const titleLimit = 60;
                        const truncatedTitle =
                        doc.title.length > titleLimit
                            ? `${doc.title.substring(0, titleLimit)}...`
                            : doc.title;

                        return (
                          <li className="col-md-3">
                          <div className="document-list-wrap" style={{ cursor: 'pointer',height:'235px' }}>
                            <h5>
                            <Link to={{pathname: `/document/${doc.slug}`}} style={{
                                color: '#244B5A',
                                textDecoration: 'none',
                                fontSize: '18px',
                                fontWeight: 'bold',
                              }}>{truncatedTitle}</Link>
                              
                            </h5>
                            <div className="document-price">
                            {getPriceDisplay(doc.document_price)}
                            </div>
                          </div>
                        </li>  
                        
                        );
                      })}
                       
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            );
          })}
          </div>
          ))}
        </div>
      </div>
      {/* Document */}
    </section>

    </main>
  );
};

export default ListingPage;
