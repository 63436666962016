import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import IndexPage from './components/IndexPage';
import ListingPage from './components/ListingPage';
import DetailPage from './components/DetailPage';
import SearchPage from './components/SearchPage';
import FreePage from './components/FreePage';

function App() {
  return (
    <div className="App">
      <Router basename="/">
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<IndexPage />} />
            <Route path="documents/:category" element={<ListingPage />} />
            <Route path="document/:slug" element={<DetailPage />} />
            <Route path="search" element={<SearchPage />} />
            <Route path="free-documents" element={<FreePage />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
