import React from 'react';
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <>
    <section className="header-main-wraper">
    <div className="container-fluid">
      <div className="row"> 
        
        <div className="col-md-3 col-6">
          <div className="logo-wraper"> <Link to="/"> <img src="/images/logo.svg" alt="logo"/> </Link> </div>
        </div>
        
        <div className="col-md-9 col-6">
          <div className="header-navigation-wrap">
            <ul>
              <li> <img src="https://kanoony.com/images/header-new-phone.svg" alt="phone"/> +971 4 587 5333 </li>
              <li> <img src="https://kanoony.com/images/message.svg" alt="email"/> Info@kanoony.com </li>
              <li> <img src="https://kanoony.com/images/location-header.svg" alt="location"/> Dubai, UAE </li>
              <li style={{marginRight:"50px"}} className="login-icon"> <a href="#"><img src="https://kanoony.com/images/user-header1.svg" alt="user"/> Login </a> </li>
              <li>
                <div className="menusitebar-main-wraper">
                  <input type="checkbox" className="check" id="checked"/>
                  <label className="menu-btn" for="checked"><span className="bar top"></span><span className="bar middle"></span><span className="bar bottom"></span></label>
                  <label className="close-menu" for="checked"></label>
                  <nav className="drawer-menu">
                    <ul>
                      <li><a href="#"> About Us</a></li>
                      <li><a href="#">Why Kanoony</a></li>
                      <li>
                      <div className="dropdown">
                  <button className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">Contract Templates</button>
                  <ul className="dropdown-menu">
                    <li> <Link className="dropdown-item" to={{pathname: `/documents/free-documents`}}> Browse Free Contracts </Link> </li>
                    <li> <Link className="dropdown-item" to={{pathname: `/documents/construction-real-estate`}}> Construction & Real Estate </Link> </li>
                    <li> <Link className="dropdown-item" to={{pathname: `/documents/employment-hr`}}> Employment & HR </Link> </li>
                    <li> <Link className="dropdown-item" to={{pathname: `/documents/it`}}> IT </Link> </li>
                    <li> <Link className="dropdown-item" to={{pathname: `/documents/commercial`}}> Commercial </Link> </li>
                    <li> <Link className="dropdown-item" to={{pathname: `/documents/corporate`}}> CORPORATE </Link> </li>
                    <li> <Link className="dropdown-item" to={{pathname: `/documents/templates/finance`}}> FINANCE </Link> </li>
                  </ul>
                </div>
                </li>
                      <li>
                      <div className="dropdown">
                  <button className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">Corporate Services</button>
                  <ul className="dropdown-menu">
                    <li><a className="dropdown-item" href="#">Setup a business </a></li>
                    <li><a className="dropdown-item" href="#">Register a trademark </a></li>
                    <li><a className="dropdown-item" href="#">Register a will </a></li>
                    <li><a className="dropdown-item" href="#">Translate a document</a></li>
                  </ul>
                </div>
                      </li>
                      <li><a href="#">Special Offers</a></li>
                      <li><a href="#">Blogs</a></li>
                      <li><a href="#">News</a></li>
                      <li><a href="#">Careers</a></li>
                      <li><a href="#">Contact us</a></li>
                    </ul>
                  </nav>
                </div>
              </li>
            </ul>
          </div>
        </div>
        
      </div>
    </div>
  </section>
  
 </>
  );
};

export default Header;