import { Outlet, Link } from "react-router-dom";
import Header from './common/Header';
import Footer from './common/Footer';
const Layout = () => {
  return (
    <>
      <Header/>
      <Outlet/>
      <Footer/>

    </>
  )
};

export default Layout;