import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import { fetcher, API_URL } from './helpers/api';
const IndexPage = () => {
  const { data: { document_categories: categories } = {}, error } = useSWR(API_URL + '/categories', fetcher);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  const redirectToBlog = (url) => {
    window.location.href = url;
  };

  const redirectToNews = (url) => {
    window.location.href = url;
  };

  if (error) {
    console.error('Error fetching categories:', error);
  }
  const [formData, setFormData] = useState({
    first_name: '',
    phone: '',
    email: '',
    comments: '',
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    // You can use the 'formData' state to access the form data
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    navigate(`/search?keywords=${encodeURIComponent(searchTerm)}`);
  };
  return (
    <section className="website-wraper">
    <section className="slider-main-wrap">
      <div className="container-fluid">
        <div className="row">
          {/* Slider text */}
          <div className="col-md-6">
            <div className="slider-text-wraper">
              <span>Streamline your business in the UAE with our</span>
              <h1>
                Contract Templates <br />
                & Corporate Services
              </h1>
              <p style={{ color: '#fff' }}>LEGAL DOCUMENT TEMPLATES • AGREEMENT TEMPLATES • POLICY TEMPLATES</p>
              <form onSubmit={handleSearchSubmit}>
                <div className="form-group">
                  <input type="search" name="keywords" value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)} className="form-control" placeholder="Search For A Contract Template" required />
                  <button type="submit">
                    <img src="images/search.svg" alt="search" />
                  </button>
                </div>
              </form>
              <div className="search-quick-link-wrap">
                <h3>Quick Links</h3>
                <ul>
                  <li> <Link to={{pathname: `/free-documents`}}> Browse Free Contracts </Link> </li>
                  <li> <Link to={{pathname: `/documents/construction-real-estate`}}> Construction & Real Estate </Link> </li>
                  <li> <Link to={{pathname: `/documents/employment-hr`}}> Employment & HR </Link> </li>
                  <li> <Link to={{pathname: `/documents/it`}}> IT </Link> </li>
                  <li> <Link to={{pathname: `/documents/commercial`}}> Commercial </Link> </li>
                  <li> <Link to={{pathname: `/documents/corporate`}}> CORPORATE </Link> </li>
                  <li> <Link to={{pathname: `/documents/templates/finance`}}> FINANCE </Link> </li>
                </ul>
              </div>
            </div>
          </div>
          {/* Slider images */}
          <div className="col-md-6">
            {/* Carousel */}
            <div id="demo" className="carousel slide" data-bs-ride="carousel">
              {/* Indicators/dots */}
              <div className="carousel-indicators">
                <button type="button" data-bs-target="#demo" data-bs-slide-to="0" className="active"></button>
                <button type="button" data-bs-target="#demo" data-bs-slide-to="1"></button>
                <button type="button" data-bs-target="#demo" data-bs-slide-to="2"></button>
                <button type="button" data-bs-target="#demo" data-bs-slide-to="3"></button>
                <button type="button" data-bs-target="#demo" data-bs-slide-to="4"></button>
                <button type="button" data-bs-target="#demo" data-bs-slide-to="5"></button>
              </div>
              {/* The slideshow/carousel */}
              <div className="carousel-inner">
                {/* Repeat this structure for other carousel items */}
                <div className="carousel-item active">
                  <div
                    className="slider-image-text-wrap"
                    style={{ background: '#ccc url(https://kanoony.com/images/slider-image1.webp) no-repeat top' }}
                  >
                    <span>HR</span>
                    <h3>Employment Contract <br />for DIFC Employees</h3>
                    <Link to={{pathname: `/documents/employment-hr`}}>Download Now</Link>
                  </div>
                </div>
                {/* Repeat this structure for other carousel items */}
                <div className="carousel-item">
                  <div
                    className="slider-image-text-wrap"
                    style={{ background: '#ccc url(https://kanoony.com/images/slider-image2.webp) no-repeat top' }}
                  >
                    <span>Corporate</span>
                    <h3>General Power <br />of Attorney Template</h3>
                    <a href="https://kanoony.com/en/document/general-power-of-attorney">Download Now</a>
                  </div>
                </div>
                {/* Repeat this structure for other carousel items */}
                <div className="carousel-item">
                  <div
                    className="slider-image-text-wrap"
                    style={{ background: '#ccc url(https://kanoony.com/images/slider-image1.webp) no-repeat top' }}
                  >
                    <span>Commercial</span>
                    <h3>Non-Disclosure and Non-Circumvention Agreement Template</h3>
                    <Link to={{pathname: `/documents/commercial`}}>
                      Download Now
                    </Link>
                  </div>
                </div>
                {/* Repeat this structure for other carousel items */}
                <div className="carousel-item">
                  <div
                    className="slider-image-text-wrap"
                    style={{ background: '#ccc url(https://kanoony.com/images/slider-image3.webp) no-repeat top' }}
                  >
                    <span>Finance</span>
                    <h3>Loan Request <br />Letter Template</h3>
                    <a href="https://kanoony.com/en/document/loan-request-letter">Download Now</a>
                  </div>
                </div>
                {/* Repeat this structure for other carousel items */}
                <div className="carousel-item">
                  <div
                    className="slider-image-text-wrap"
                    style={{ background: '#ccc url(https://kanoony.com/images/slider-image4.webp) no-repeat top' }}
                  >
                    <span>IT</span>
                    <h3>Website Disclaimer <br />Template</h3>
                    <Link to={{pathname: `/documents/it`}}>Download Now</Link>
                  </div>
                </div>
                {/* Repeat this structure for other carousel items */}
                <div className="carousel-item">
                  <div
                    className="slider-image-text-wrap"
                    style={{ background: '#ccc url(https://kanoony.com/images/slider-image5.webp) no-repeat top' }}
                  >
                    <span>Construction</span>
                    <h3>Office Lease <br />Agreement Template</h3>
                    <Link to={{pathname: `/documents/construction-real-estate`}}>Download Now</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  <section className="content-wraper"> 
  <div className="advantage-bar-wrap">
      <div className="container-fluid">
        <ul className="row">
          <li className="col-md-3">
            <div className="listing-advantage-wrap">
              <div className="listing-advantage-icon"><img src="images/advantage-icon1.svg" alt="icon" /></div>
              <div className="listing-advantage-text">
                <h4> SET UP<br />
                  A BUSINESS </h4>
              </div>
            </div>
          </li>
          <li className="col-md-3">
            <div className="listing-advantage-wrap">
              <div className="listing-advantage-icon"><img src="images/advantage-icon2.svg" alt="icon" /></div>
              <div className="listing-advantage-text">
                <h4> REGISTER<br />
                  A TRADEMARK </h4>
              </div>
            </div>
          </li>
          <li className="col-md-3">
            <div className="listing-advantage-wrap">
              <div className="listing-advantage-icon"><img src="images/advantage-icon3.svg" alt="icon" /></div>
              <div className="listing-advantage-text">
                <h4>REGISTER<br />
                  A WILL </h4>
              </div>
            </div>
          </li>
          <li className="col-md-3">
            <div className="listing-advantage-wrap">
              <div className="listing-advantage-icon"><img src="images/advantage-icon4.svg" alt="icon" /></div>
              <div className="listing-advantage-text">
                <h4>TRANSLATE<br />
                  A DOCUMENT</h4>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <section className="pricing-main-wraper slide-div" id="pricing" debog="3">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h5> Contract Template Packages<br /></h5>
          </div>
          <div className="col-md-12">
            <div className="pricing-listing-wraper" style={{textAlign:'center'}}>
              <ul>
                <li>
                  <div className="price-list-wrap">
                    <div className="price-tag-wrap">
                      <span> AED <strong> 240 </strong> </span>
                    </div>
                    <h5 style={{ fontSize: '26px' }}> Monthly </h5>
                    <h5 style={{ marginTop: '0px' }}> SAVE MORE THAN 50% </h5>
                    <div className="price-info-wrap">
                      <p>
                        Download any 5 Templates/Month. <br />
                        Edit and Sign online. <br />
                        Access to the full library. <br />
                        Download as MS Word.
                      </p>
                    </div>
                    <div className="button-pricing">
                      <a href="javascript:;"> Get it Now </a>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="price-list-wrap">
                    <div className="price-tag-wrap">
                      <span> AED <strong> 2000 </strong> </span>
                    </div>
                    <h5 style={{ fontSize: '26px' }}> Yearly </h5>
                    <h5 style={{ marginTop: '0px' }}>SAVE MORE THAN 75%</h5>
                    <div className="price-info-wrap">
                      <p>
                        Download any 60 Templates/Year. <br />
                        Edit and Sign online.<br />
                        Access to the full library.<br />
                        Download as MS Word.
                      </p>
                    </div>
                    <div className="button-pricing">
                      <a href="javascript:;"> Get it Now </a>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div className="cta-main-wraper">
      <div className="container">
        <div className="row">
          {/* cta text */}
          <div className="col-md-6">
            <div className="cta-text-wrp">
              <span> REFER AND EARN </span>
              <h5> UP TO AED 5000* </h5>
              <p>Enjoy cash rewards by referring your friends, family, or co-workers to Kanoony who are looking to start a business in the UAE </p>
              <a href="#">Start EARNING</a>
            </div>
          </div>
          {/* cta text */}

          {/* cta image */}
          <div className="col-md-6">
            <div className="cta-image-wraper">
              <img src="images/cta-image.png" alt="image" />
            </div>
          </div>
          {/* cta image */}
        </div>
      </div>
    </div>
   
    <div className="contact-form-wraper">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="contact-form-wrap">
              <h4> Request a call back </h4>
              <form onSubmit={handleSubmit} method="POST" className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Name*"
                      value={formData.first_name}
                      onChange={handleChange}
                      name="first_name"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Phone Number*"
                      value={formData.phone}
                      onChange={handleChange}
                      name="phone"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email*"
                      value={formData.email}
                      onChange={handleChange}
                      name="email"
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="textarea-group">
                    <textarea
                      className="form-control"
                      rows="4"
                      placeholder="Message*"
                      value={formData.comments}
                      onChange={handleChange}
                      name="comments"
                    ></textarea>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="button-group">
                    <button type="submit">
                      
                      &nbsp; SUBMIT
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="blog-listing-wraper news-listing-wraper">
      <div className="container">
        <h3 style={{ cursor: 'pointer' }} onClick={() => redirectToBlog('https://kanoony.com/en/blog')}> Blogs </h3>
        <div className="blog-bottom-wraper">
          <ul className="row">
            <li className="col-md-4">
              <a href="https://kanoony.com/en/blog/simplified-process-with-contract-templates-in-uae">
                <span style={{ cursor: 'pointer' }} onClick={() => redirectToBlog('https://kanoony.com/en/blog')}> Blogs </span>
                <img style={{ minHeight: '237px' }} src="https://kanoony.com/storage/news/media/Simplified-process-with-contract-templates-in-UAE.jpg" alt="image" />
              </a>
              <div className="text-blog-wrap">
                <h5> Simplified process with contract templates in UAE </h5>
                <p> Contracts are essential to establish legal relationships. Access to trusted and effective contract templates is essential for businesses in Dubai, UAE, because of today's rapidly evolving business env...<a href="https://kanoony.com/en/blog/simplified-process-with-contract-templates-in-uae"> Read more &gt;&gt; </a></p>
              </div>
            </li>
            <li className="col-md-4">
              <a href="https://kanoony.com/en/blog/the-importance-of-trademark-registration-in-uae-for-business-owners">
                <span style={{ cursor: 'pointer' }} onClick={() => redirectToBlog('https://kanoony.com/en/blog')}> Blogs </span>
                <img style={{ minHeight: '237px' }} src="https://kanoony.com/storage/news/media/importance%20of%20trademark%20registration%20in%20UAE%20for%20business%20owners.jpg" alt="image" />
              </a>
              <div className="text-blog-wrap">
                <h5> The importance of trademark registration in UAE for business owners </h5>
                <p> According to the trademark laws in force in the United Arab Emirates, any physical or juristic person may register a trademark. This means that whether it is an individual or a company, all persons ar...<a href="https://kanoony.com/en/blog/the-importance-of-trademark-registration-in-uae-for-business-owners"> Read more &gt;&gt; </a></p>
              </div>
            </li>
            <li className="col-md-4">
              <a href="https://kanoony.com/en/blog/legal-protection-offered-by-contract-templates-in-uae">
                <span style={{ cursor: 'pointer' }} onClick={() => redirectToBlog('https://kanoony.com/en/blog')}> Blogs </span>
                <img style={{ minHeight: '237px' }} src="https://kanoony.com/storage/news/media/Legal%20Protection%20Offered%20by%20Contract%20Templates%20in%20UAE.jpg" alt="image" />
              </a>
              <div className="text-blog-wrap">
                <h5> Legal protection offered by contract templates in UAE </h5>
                <p> A contract establishes a relationship between two or more parties, where such relationship is governed by a set of provisions. A breaching party is held liable for breach of such provisions. Breach ma...<a href="https://kanoony.com/en/blog/legal-protection-offered-by-contract-templates-in-uae"> Read more &gt;&gt; </a></p>
              </div>
            </li>
            <li className="col-md-4">
              <a href="https://kanoony.com/en/blog/the-time-saving-benefits-of-contract-templates-in-the-uae">
                <span style={{ cursor: 'pointer' }} onClick={() => redirectToBlog('https://kanoony.com/en/blog')}> Blogs </span>
                <img style={{ minHeight: '237px' }} src="https://kanoony.com/storage/news/media/Benefits-of-Contract-Templates-.jpg" alt="image" />
              </a>
              <div className="text-blog-wrap">
                <h5> The time-saving benefits of contract templates in the UAE </h5>
                <p> In today's fast-paced business landscape, time is of the essence. SME, entrepreneurs and business professionals in the United Arab Emirates (UAE) are constantly looking for ways to streamline their op...<a href="https://kanoony.com/en/blog/the-time-saving-benefits-of-contract-templates-in-the-uae"> Read more &gt;&gt; </a></p>
              </div>
            </li>
            <li className="col-md-4">
              <a href="https://kanoony.com/en/blog/should-husband-and-wife-have-separate-wills-in-dubai">
                <span style={{ cursor: 'pointer' }} onClick={() => redirectToBlog('https://kanoony.com/en/blog')}> Blogs </span>
                <img style={{ minHeight: '237px' }} src="https://kanoony.com/storage/news/media/Separate-Wills-in-Dubai.jpg" alt="image" />
              </a>
              <div className="text-blog-wrap">
                <h5> Should husband and wife have separate wills in Dubai? </h5>
                <p> As Dorothy Sayers says, “There is something about wills which brings out the worst side of human nature. People who under ordinary circumstances are perfectly upright and amiable, go as curly as...<a href="https://kanoony.com/en/blog/should-husband-and-wife-have-separate-wills-in-dubai"> Read more &gt;&gt; </a></p>
              </div>
            </li>
            <li className="col-md-4">
              <a href="https://kanoony.com/en/blog/business-contract-templates-in-dubai-uae-a-comprehensive-guide">
                <span style={{ cursor: 'pointer' }} onClick={() => redirectToBlog('https://kanoony.com/en/blog')}> Blogs </span>
                <img style={{ minHeight: '237px' }} src="https://kanoony.com/storage/news/media/Business-contract-template.jpg" alt="image" />
              </a>
              <div className="text-blog-wrap">
                <h5> Business contract templates in Dubai, UAE: A comprehensive guide </h5>
                <p> The main thing that comes to your mind when you are starting or running a business is growth. However, it is crucial to ensure that you conduct business and trade transactions in a manner that is regu...<a href="https://kanoony.com/en/blog/business-contract-templates-in-dubai-uae-a-comprehensive-guide"> Read more &gt;&gt; </a></p>
              </div>
            </li>
          </ul>
        </div>
        <div className="view-all-button-blog news-all-button-blog">
          <a href="https://kanoony.com/en/blog"> Blogs </a>
        </div>
      </div>
    </div>
    <div className="blog-listing-wraper news-listing-wraper">
      <div className="container">
        <h3 style={{ cursor: 'pointer' }} onClick={() => redirectToNews('https://kanoony.com/en/news-articles')}> News </h3>
        <div className="blog-bottom-wraper">
          <ul className="row">
            <li className="col-md-4">
              <a href="https://kanoony.com/en/news/kanoony-the-uaes-rising-star-in-the-corporate-world">
                <span style={{ cursor: 'pointer' }} onClick={() => redirectToNews('https://kanoony.com/en/news-articles')}> News </span>
                <img src="https://kanoony.com/storage/news/media/UAE-RISING-STAR-IN-THE-CORPORATE-WORLD-3.jpg" alt="image" style={{}} />
              </a>
              <div className="text-blog-wrap">
                <h5> “KANOONY” The UAE’s rising star in the corporate world </h5>
                <p> A Complete Corporate and Legal Services Ecosystem Under One Roof
                  In recent years....... <a href="https://kanoony.com/en/news/kanoony-the-uaes-rising-star-in-the-corporate-world"> Read more &gt;&gt; </a></p>
              </div>
            </li>
            <li className="col-md-4">
              <a href="https://kanoony.com/en/news/a-guide-for-entrepreneurs-how-to-protect-your-assets-in-the-uae">
                <span style={{ cursor: 'pointer' }} onClick={() => redirectToNews('https://kanoony.com/en/news-articles')}> News </span>
                <img src="https://kanoony.com/storage/news/media/Dubai-SKy2.webp" alt="image" />
              </a>
              <div className="text-blog-wrap">
                <h5> A guide for entrepreneurs: how to protect your assets in the UAE </h5>
                <p> Every individual has different assets – money, investments or properties – and it is imp...... <a href="https://kanoony.com/en/news/a-guide-for-entrepreneurs-how-to-protect-your-assets-in-the-uae"> Read more &gt;&gt; </a></p>
              </div>
            </li>
            <li className="col-md-4">
              <a href="https://kanoony.com/en/news/why-start-your-business-in-dubai">
                <span style={{ cursor: 'pointer' }} onClick={() => redirectToNews('https://kanoony.com/en/news-articles')}> News </span>
                <img src="https://kanoony.com/storage/news/media/Dubai-Skyline.webp" alt="image" />
              </a>
              <div className="text-blog-wrap">
                <h5> Why start your business in Dubai? </h5>
                <p> Starting a business overseas can be a daunting prospect. When the foreign country in question is the...... <a href="https://kanoony.com/en/news/why-start-your-business-in-dubai"> Read more &gt;&gt; </a></p>
              </div>
            </li>
          </ul>
        </div>
        <div className="view-all-button-blog news-all-button-blog">
          <a href="https://kanoony.com/en/news-articles"> News </a>
        </div>
      </div>
    </div>
 
  
</section>
</section>
  );
};

export default IndexPage;