const API_URL = 'https://kanoony.com/api';
const fetcher = async (url) => {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error('Failed to fetch data');
    }
    return response.json();
};


  
export { fetcher, API_URL };