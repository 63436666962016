import React, { useState } from 'react';
import { Link, useParams } from "react-router-dom";
import useSWR from 'swr';
import { fetcher, API_URL } from './helpers/api';
import bootstrap from 'bootstrap/dist/js/bootstrap.min.js';
const DetailPage = () => {
    const { slug } = useParams();
    const [modalContent, setModalContent] = useState(null);


    const { data: { data: docu } = {}, documentError } = useSWR(API_URL + '/document/'+slug, fetcher);

    if (documentError) {
      console.error('Error fetching Document:', documentError);
    }

   
    
    const dateFormat = (date) => {
        const originalDateString = date;
        const originalDate = new Date(originalDateString);
        const formattedDate = `${originalDate.getDate().toString().padStart(2, '0')}-${(originalDate.getMonth() + 1).toString().padStart(2, '0')}-${originalDate.getFullYear()}`;
        return formattedDate;
    };

    const getPriceDisplay = (price) => {
      const priceInt = parseInt(price, 10);
      if (priceInt > 0) {
        return `${priceInt} AED`;
      } else {
        return 'Free';
      }
    };


    
    const sectionStyle = {
        backgroundImage: "url(/images/construction-workers.jpg)",
        backgroundPosition: "bottom",
    };  
    return (
    <main>
      <div className="inner-aboutslider-wraper">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1> {docu?.document.title} </h1>
            </div>
            <div className="col-md-12">
              <div className="breadcrumbs-wrap"> <a href="#"> Home </a> <span>  </span> <a href="#"> Detail </a> </div>
            </div>
          </div>
        </div>
      </div>
      <section className="content-wraper">
      {/* document */}
      <div className="document-detail-main-wraper">
        <div className="container">
          {/* heading */}
          <div className="document-detail-heading">
            <div className="document-detail-icon">
              <img src="/images/icon-document.png" alt="document" />
            </div>
            <div className="document-detail-text">
              <h4> {docu?.document.title} </h4>
              <div className="review-star-wrap">
                <span> 0 Reviews </span>
                <a href="#">
                  <img src="/images/star.png" alt="star" />
                </a>
                <a href="#">
                  <img src="/images/star.png" alt="star" />
                </a>
                <a href="#">
                  <img src="/images/star.png" alt="star" />
                </a>
                <a href="#">
                  <img src="/images/star.png" alt="star" />
                </a>
                <a href="#">
                  <img src="/images/star.png" alt="star" />
                </a>
              </div>
            </div>
            <div className="document-info-wraper">
              <div className="container">
                <div className="row">
                  {/* Basic Info */}
                  <div className="col-md-3">
                    <div className="basic-info-text-wraper">
                      <h4> Basic Info </h4>
                      <ul>
                        <li>
                          Applicable Region <strong>UAE</strong>
                        </li>
                        <li>
                          Available Languages <br />
                          <span>
  {docu?.document.combineattacment
    ? 'English & Arabic'
    : docu?.document.arabicattachment
    ? 'Arabic'
    : 'English'}
</span>
                        </li>
                        <li>
                          Document No <strong>0000{docu?.document.document_id}</strong>
                        </li>
                        <li>
                          Version <strong>{docu?.document.version}</strong>
                        </li>
                        <li>
                          Last Updated <strong>{dateFormat(docu?.document.created_at)}</strong>
                        </li>
                        <li>
                          Reviews <strong>0</strong>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* Basic Info */}

                  {/* Description */}
                  <div className="col-md-6">
                    <div className="document-discription-wraper">
                      <h5> Description </h5>
                      <div dangerouslySetInnerHTML={{ __html: docu?.document.detailsDescription }} />
                    </div>
                  </div>
                  {/* Description */}

                  {/* Get Document */}
                  <div className="col-md-3">
                    <div className="get-document-wrap">
                      <h5> {getPriceDisplay(docu?.document_full.document_price)} </h5>
                      <a href="#"> Get this Document </a>
                    </div>
                    <div className="related-document-list-wrap">
                      <h3> Related Documents </h3>
                      <ul>

                        <li>
                          <a href="#">Diversity And Equality Policy</a>
                        </li>
                        <li>
                          <a href="#">Harassment And Bullying Policy Template</a>
                        </li>
                        {/* Add more related documents */}
                      </ul>
                    </div>
                  </div>
                  {/* Get Document */}
                </div>
              </div>
            </div>
            <div className="clearfix"></div>
          </div>
          {/* heading */}
        </div>
      </div>
      {/* document */}
    </section>
      </main>
  );
};

export default DetailPage;