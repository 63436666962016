import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import bootstrap from 'bootstrap/dist/js/bootstrap.min.js';
import useSWR from 'swr';
import { fetcher, API_URL } from './helpers/api';

const FreePage = () => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const myButtonRef = useRef(null);
  const keywords = queryParams.get('keywords') || '';
  const [searchKeywords, setSearchKeywords] = useState(keywords);
  const [modalContent, setModalContent] = useState(null);
  const [modalStripe, setModalStripe] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  const MODAL_ID = 'exampleModalToggle';
  const STRIPE_MODAL_ID = 'stripeModalToggle';
  const SUCCESS_MODAL_ID = 'successModalToggle';
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    setSearchKeywords(keywords);
  }, [search]);

  const { data: { document_categories: categories } = {}, categoriesError } = useSWR(API_URL + '/categories', fetcher, { revalidateOnFocus: false });
  const { data: { categories: sub_categories } = {}, subCategoriesError, isValidating: isSubCategoriesLoading } = useSWR(
    API_URL + `/free-web/${searchKeywords?searchKeywords:'all-documents'}`,
    fetcher,
    { revalidateOnFocus: false }
  );

 // console.log(sub_categories);

  if (categoriesError) {
    console.error('Error fetching categories:', categoriesError);
  }

  if (subCategoriesError) {
    console.error('Error fetching Sub categories:', subCategoriesError);
  }

  const toggleModal = (modalId) => {
    const modalToggle = new bootstrap.Modal(document.getElementById(modalId));
    modalToggle.show();
  };

  const closeModal = (modalId) => {
    const modalToggle = new bootstrap.Modal(document.getElementById(modalId));
    modalToggle.hide();
  };

  const triggerClick = () => {
    if (myButtonRef.current) {
      myButtonRef.current.click();
    }
  };

  const getDocumentClasses = (document) => {
    return document.title.length > 60 ? 'truncated' : '';
  };

  const dateFormat = (date) => {
    const originalDateString = date;
    const originalDate = new Date(originalDateString);
    const formattedDate = `${originalDate.getDate().toString().padStart(2, '0')}-${(originalDate.getMonth() + 1).toString().padStart(2, '0')}-${originalDate.getFullYear()}`;
    return formattedDate;
  };

  const handlePaymentSubmit = async (event) => {
    event.preventDefault();
  
    if (!stripe || !elements) {
      return;
    }
    
    const fullName = document.getElementById('fullName').value;

    const email = document.getElementById('email').value;

    if (!fullName || !email ) {
      alert('Please fill in all required fields.');
      return;
    }

    const cardElement = elements.getElement(CardElement);
  
    const { token, error } = await stripe.createToken(cardElement);
    
  
    if (error) {
      console.error(error);
    } else {
      triggerClick();
      toggleModal(SUCCESS_MODAL_ID);
      const response = await fetch(API_URL + '/document-detail/'+modalContent?.slug+'?name='+fullName+'&email='+email+'&company_id=16');
      const documentLink =
        'https://kanoony.com/output/uploaded_Files/' +
        (modalContent?.combineattacment
          ? 'combineattacment/' + modalContent?.combineattacment
          : modalContent?.arabicattachment
          ? 'arabicattachment/' + modalContent?.arabicattachment
          : modalContent?.englishattachment);

      const downloadLink = document.createElement('a');
      downloadLink.href = documentLink;
      downloadLink.target = '_blank';
      downloadLink.download = modalContent?.combineattacment || modalContent?.arabicattachment || modalContent?.englishattachment;

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    navigate(`/search?keywords=${encodeURIComponent(searchTerm)}`);
  };

  const sectionStyle = {
    backgroundImage: "url(/images/construction-workers.jpg)",
    backgroundPosition: "bottom",
  };
  const inputStyle = {
    fontSize: '15px',
    color: 'black',
    width: '90%',
    height: '50px',
    border: '2px solid #dfdfdf',
    borderRadius: '10px',
    padding: '0 10px',
    paddingTop: '0px',
    position: 'initial',
    display: 'block',
    paddingTop: '14px',
    marginLeft: '25px'
  };

  const getPriceDisplay = (price) => {
    const priceInt = parseInt(price, 10);
    if (priceInt > 0) {
      return `${priceInt} AED`;
    } else {
      return 'Free';
    }
  };
  

  return (
    <main>
    <div className="inner-aboutslider-wraper">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1> Free Documents </h1>
          </div>
          <div className="col-md-12">
            <div className="breadcrumbs-wrap"> <a href="#"> Home </a> <span> </span> <a href="#"> Documents </a> </div>
          </div>
        </div>
      </div>
    </div>
    <section className="content-wraper">
    {/* Document */}
    <div className="inner-document-listing-wraper">
      <div className="container">
        {/* Button, Search */}
        <div className="documen-bt-search">
          <div className="row">
            {/* Button */}
            <div className="col-md-8">
              <div className="button-document">
              <Link to={{pathname: `/free-documents`}}> Free Documents </Link>
              </div>
            </div>
            {/* Button */}

            {/* Search */}
            <div className="col-md-4">
            <form onSubmit={handleSearchSubmit}>
                <div className="search-field-wrap">
                  <input type="text" name="keywords" value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)} className="form-control" placeholder="Search" />
                  <button>
                    <img src="/images/search-news.svg" alt="search" />
                  </button>
                </div>
                </form>
            </div>
            {/* Search */}
          </div>
        </div>
        {/* Button, Search */}
        {isSubCategoriesLoading && (
          <div className="loader-container">
            <div className="loader"></div>
          </div>
        )}
        {/* Listing Accordion #1 */}
        {!isSubCategoriesLoading && sub_categories?.map((sub_category) => (
        <div className="document-listing-accordin-wrap" key={sub_category.title}>
          <h4>{sub_category.title}</h4>
          
            <div className="card">
              <div >
                <div className="card-body">
                  <div className="document-listing-wraper">
                    <ul className="row">
                    {sub_category.data?.map((document, index) => {
                      const titleLimit = 60;
                      const truncatedTitle =
                      document.title.length > titleLimit
                          ? `${document.title.substring(0, titleLimit)}...`
                          : document.title;

                      return (
                        <li className="col-md-3">
                        <div className="document-list-wrap" style={{ cursor: 'pointer',height:'235px' }}>
                          <h5>
                          <Link to={{pathname: `/document/${document.slug}`}} style={{
                              color: '#244B5A',
                              textDecoration: 'none',
                              fontSize: '18px',
                              fontWeight: 'bold',
                            }}>{truncatedTitle}</Link>
                            
                          </h5>
                          <div className="document-price">
                          {getPriceDisplay(document.document_price)}
                          </div>
                        </div>
                      </li>  
                      
                      );
                    })}
                     
                    </ul>
                  </div>
                </div>
              </div>
            </div>
        </div>
        ))}
      </div>
    </div>
    {/* Document */}
  </section>

  </main>
  );
};

export default FreePage;
